export const chatbotStepsEN = [
  {
    id: "1",
    message: "What's your name?",
    trigger: "2",
  },
  {
    id: "2",
    user: true,
    trigger: "3",
  },
  {
    id: "3",
    message: "Hola {previousValue}, hay olor a puto, sos vos me imagino?",
    end: true,
  },
];

