
export const theme = {
    background: '#f5f8fb',
    fontFamily: 'Roboto',
    headerBgColor: '#bf281f',
    headerFontColor: '#fff',
    headerFontSize: '17px',
    botBubbleColor: '#fff',
    botFontSize: '15px',
    botFontColor: '#333',
    userBubbleColor: '#dcecfe',
    userFontColor: '#333',
};
  