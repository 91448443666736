import React from "react";
import "./App.css";
import { ThemeProvider } from 'styled-components';
import ChatBot from "react-simple-chatbot";
import { theme } from "./theme";
import { chatbotStepsEN } from "./chatbotStepsEN";
import { chatbotStepsES } from "./chatbotStepsES";



function App() {
    // Parse the query string
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const lang = (urlParams.get('lang') || 'es').toLowerCase(); // Convert to lowercase and default to Spanish if no lang parameter
  
    // Decide the header title based on the language
    const headerTitleVar = lang === "en" ? "Alkanos Assistant" : "Asistente Alkanos";

    // Decide which steps to use based on the language
    const chatbotSteps = lang === 'en' ? chatbotStepsEN : chatbotStepsES;

    // Define the handleEnd function
    const handleEnd = ({ steps, values }) => {
        const url = 'https://yourdomain.com/your-php-script.php'; // Your PHP script URL

        // Prepare the data for sending
        const data = {
            country: steps.countryInput?.value,
            industry: steps.industryInput?.value,
            name: steps.nameInput?.value,
            email: steps.emailInput?.value,
            phone: steps.phoneInput?.value,
        };

        // Use fetch to send data to your server
        fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        })
        .then(response => response.json())
        .then(data => {
            console.log('Success:', data);
        })
        .catch((error) => {
            console.error('Error:', error);
        });
    };

    return (
      <div className="App">
        <ThemeProvider theme={theme}>
          <ChatBot
            headerTitle={headerTitleVar}
            floating={true}
            botAvatar={'https://www.alkanos.com.ar/favicons/apple-touch-icon.png'}
            enableMobileAutoFocus={true}
            enableSmoothScroll={true}
            hideUserAvatar={true}
            steps={chatbotSteps}
            theme={theme}
            handleEnd={handleEnd} // Add the handleEnd callback here
          />

        </ThemeProvider>
      </div>
    );
}
  
export default App;
