export const chatbotStepsES = [
  {
    id: "1",
    message:
      "Bienvenido a Alkanos. Nuestro horario de atención es de lunes a viernes de 08:30 a 17hs. ¿En qué lo puedo ayudar?",
    trigger: "2",
  },
  {
    id: "2",
    hideInput: true,
    options: [
      { value: 1, label: "Estoy buscando productos de Alkanos", trigger: "3" },
      {
        value: 2,
        label: "Tengo una consulta técnica",
        trigger: "clienteCheck",
      },
      {
        value: 3,
        label: "Tengo una consulta administrativa",
        trigger: "clienteCheck",
      },
      {
        value: 4,
        label: "Quiero hablar con un operador",
        trigger: "hablar_con_humano",
      },
    ],
  },
  {
    id: "3",
    message: "¿Qué tipo de productos le interesa explorar?",
    trigger: "categorias_productos",
  },
  {
    id: "categorias_productos",
    hideInput: true,
    options: [
      { value: "polioles", label: "Polioles", trigger: "info_polioles" },
      {
        value: "isocianatos",
        label: "Isocianatos",
        trigger: "info_isocianatos",
      },
      {
        value: "aditivos_pu",
        label: "Aditivos PU",
        trigger: "info_aditivos_pu",
      },
      {
        value: "sistemas_pu_flexibles",
        label: "Sistemas PU Flexibles",
        trigger: "info_sistemas_pu_flexibles",
      },
      {
        value: "sistemas_pu_rigidos",
        label: "Sistemas PU Rígidos",
        trigger: "info_sistemas_pu_rigidos",
      },
      {
        value: "sistemas_pu_microcelular",
        label: "Sistemas PU Microcelular",
        trigger: "info_sistemas_pu_microcelular",
      },
      { value: "glicoles", label: "Glicoles", trigger: "info_glicoles" },
      { value: "maquinaria", label: "Maquinaria", trigger: "info_maquinaria" },
    ],
  },
  {
    id: "info_polioles",
    message:
      "En Alkanos contamos con uno de los portfolios más amplios del mercado. Nuestros polioles se utilizan en espumas convencionales, espumas especiales (viscoelástica, alta resiliencia e Hipersoft), aplicaciones para espumas rígidas y para revestimientos, selladores y elastómeros. Ofrecemos nuestros polioles en diversas presentaciones (granel, IBC, tambor, entre otros). Consulte a nuestros especialistas para seleccionar el producto que mejor se adecúe a sus necesidades.",
    trigger: "pregunta_nueva",
  },
  {
    id: "info_isocianatos",
    message:
      "Magnífica decisión. Nuestros isocianatos son fundamentales para crear poliuretanos de la más alta calidad. En Alkanos, nos aseguramos de que cada producto no solo cumpla con sus expectativas sino que también contribuya a un futuro más sostenible. ¿Desea conocer más sobre cómo podemos trabajar juntos?",
    trigger: "pregunta_nueva",
  },
  {
    id: "info_aditivos_pu",
    message:
      "Una excelente opción. Los aditivos UNIKAT de Alkanos potenciarán las propiedades de sus espumas de poliuretano, mejorando tanto el proceso de producción como el desempeño final del producto. Nuestra amplia selección está diseñada para satisfacer sus necesidades específicas. ¿Interesado en mejorar sus productos con nuestros aditivos?",
    trigger: "pregunta_nueva",
  },
  {
    id: "info_sistemas_pu_flexibles",
    message:
      "Perfecta elección. Con nuestros sistemas ALKURAN F, garantizamos el confort y la durabilidad que busca en la espuma flexible. Nuestro compromiso es facilitar sus procesos de fabricación, ofreciendo productos de calidad superior para muebles, colchones y mucho más. ¿Le gustaría saber cómo podemos enriquecer sus proyectos?",
    trigger: "pregunta_nueva",
  },
  {
    id: "info_sistemas_pu_rigidos",
    message:
      "Una elección acertada. Nuestros sistemas de espumas rígidas ALKURAN R son la solución ideal para maximizar la eficiencia energética y ofrecer un excelente aislamiento térmico. ¿Está interesado en descubrir cómo nuestras soluciones pueden contribuir a sus proyectos de aislamiento y eficiencia?",
    trigger: "pregunta_nueva",
  },
  {
    id: "info_sistemas_pu_microcelular",
    message:
      "Una selección inteligente. Nuestra gama de sistemas para espumas microcelulares abre un abanico de posibilidades para aplicaciones rígidas y flexibles. Si busca innovación y alto rendimiento, nuestras soluciones son su mejor aliado. ¿Desea explorar más sobre cómo podemos impulsar sus proyectos?",
    trigger: "pregunta_nueva",
  },
  {
    id: "info_glicoles",
    message:
      "Una decisión prudente. Nuestros glicoles de alta pureza son versátiles y esenciales para diversas aplicaciones industriales y comerciales. En Alkanos, ofrecemos soluciones que aseguran resultados excepcionales. ¿Está interesado en conocer más sobre cómo nuestros glicoles pueden beneficiar sus operaciones?",
    trigger: "pregunta_nueva",
  },

  {
    id: "info_maquinaria",
    message:
      "Ofrecemos máquinas de firmas líderes en el mundo para la fabricación de espumas de poliuretano. ¿Está interesado en conocer más sobre las maquinarias que comercializamos?",
    trigger: "pregunta_nueva",
  },

  {
    id: "pregunta_nueva",
    hideInput: true,
    options: [
      { value: "si", label: "Hacer otra pregunta", trigger: "2" },
      {
        value: "no",
        label: "Quiero que me contacten",
        trigger: "askForCountry",
      },
    ],
  },

  {
    id: "hablar_con_humano",
    message:
      "¡Claro que sí! Para hablar con un representante, por favor llama al +54 11 1234-5678 o deja un mensaje con tu número y te llamaremos.",
    end: true,
  },

  // CLIENTE EXISTENTE

  {
    id: "clienteCheck",
    message: "¿Ya es cliente de Alkanos?",
    trigger: "clienteOpcion",
  },
  {
    id: "clienteOpcion",
    options: [
      { value: "si", label: "Sí", trigger: "infoClienteExistente" },
      { value: "no", label: "No", trigger: "askForCountry" },
    ],
  },
  {
    id: "infoClienteExistente",
    message: "Por favor, ingrese el nombre de su empresa:",
    trigger: "nombreEmpresaInput",
  },
  {
    id: "nombreEmpresaInput",
    user: true,
    trigger: "nombreYApellidoClienteExistente",
  },
  {
    id: "nombreYApellidoClienteExistente",
    message: "Ahora, por favor, ingrese su nombre y apellido:",
    trigger: "nombreApellidoInput",
  },
  {
    id: "nombreApellidoInput",
    user: true,
    trigger: "telefonoContactoClienteExistente",
  },
  {
    id: "telefonoContactoClienteExistente",
    message: "Finalmente, necesitamos su teléfono de contacto:",
    trigger: "telefonoInput",
  },
  {
    id: "telefonoInput",
    user: true,
    trigger: "consultaMensaje",
  },
  {
    id: "consultaMensaje",
    message:
      "Por favor ingrese su consulta. Uno de nuestros representantes lo contactará a la brevedad.",
    trigger: "userMessageClienteExistente",
  },
  {
    id: "userMessageClienteExistente",
    user: true,
    trigger: "confirmationClienteExistente",
  },
  {
    id: "confirmationClienteExistente",
    message:
      "Gracias por su información, nos pondremos en contacto con usted a la brevedad.",
    trigger: "additionalHelp",
  },

  //   NUEVO CLIENTE

  {
    id: "askForCountry",
    message: "Por favor, ingrese su país:",
    trigger: "countryInput",
  },
  {
    id: "countryInput",
    user: true,
    trigger: "askForIndustry",
  },
  {
    id: "askForIndustry",
    message:
      "{previousValue}! Perfecto! Ahora, por favor, indique su industria:",
    trigger: "industryInput",
  },
  {
    id: "industryInput",
    options: [
      {
        value: "Espumas Flexibles",
        label: "Espumas Flexibles",
        trigger: "askForName",
      },
      { value: "Construccion", label: "Construcción", trigger: "askForName" },
      { value: "Refrigeracion", label: "Refrigeración", trigger: "askForName" },
      { value: "Automotriz", label: "Automotriz", trigger: "askForName" },
      {
        value: "Consumo y Cuidado Personal",
        label: "Consumo y Cuidado Personal",
        trigger: "askForName",
      },
      {
        value: "Petroleo Gas y Mineria",
        label: "Petróleo, Gas y Minería",
        trigger: "askForName",
      },
      {
        value: "Otras Aplicaciones",
        label: "Otras Aplicaciones",
        trigger: "specifyOtherIndustry",
      },
    ],
  },
  {
    id: "specifyOtherIndustry",
    message: "Por favor, especifique cuál es su industria:",
    trigger: "otherIndustryInput",
  },
  {
    id: "otherIndustryInput",
    user: true,
    trigger: "askForName",
  },
  {
    id: "askForName",
    message:
      "{previousValue}! Trabajamos con muchas personas de esta industria. ¿Dígame, cuál es su nombre y apellido?",
    trigger: "nameInput",
  },
  {
    id: "nameInput",
    user: true,
    trigger: "askForEmail",
  },
  {
    id: "askForEmail",
    message: "Excelente! Ahora necesitamos su email:",
    trigger: "emailInput",
  },
  {
    id: "emailInput",
    user: true,
    trigger: "askForPhone",
  },
  {
    id: "askForPhone",
    message: "Casi terminamos! Por favor, ingrese su teléfono de contacto:",
    trigger: "phoneInput",
  },
  {
    id: "phoneInput",
    user: true,
    trigger: "consultaMensajeNuevo",
  },
  {
    id: "consultaMensajeNuevo",
    message: "Por favor, ingrese su mensaje o consulta:",
    trigger: "userMessageNuevo",
  },
  {
    id: "userMessageNuevo",
    user: true,
    trigger: "confirmation",
  },
  {
    id: "confirmation",
    message:
      "Mensaje enviado, {previousValue}. Te contactaremos a la brevedad, ¡muchas gracias!",
    trigger: "additionalHelp",
  },

  {
    id: "additionalHelp",
    hideInput: true,
    options: [
      { value: "si", label: "Hacer otra pregunta", trigger: "2" },
      {
        value: "no",
        label: "Quiero que me contacten",
        trigger: "askForCountry",
      },
    ],
  },
  {
    id: "endConversation",
    message:
      "Gracias por contactarnos, recuerde seguirnos en linkedIn (https://www.linkedin.com/company/alkanos/?viewAsMember=true) ¡que tenga un buen día!",
    end: true,
  },
];
